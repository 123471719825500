import React from 'react';
import PropTypes from 'prop-types';


export default class LogoPreview extends React.Component {
    static propTypes = {
        brand: PropTypes.string.isRequired,
        className: PropTypes.string,
    };

    render() {
        let className = 'marks';
        if(this.props.className) {
            className += ` ${this.props.className}`;
        }
        return (
            <figure className={className}>
                <i style={{backgroundImage:
                    `url(/${this.props.brand}-stacked.png)`,
                }}/>
                <i style={{backgroundImage:
                    `url(/${this.props.brand}-stacked-white.png)`,
                }}/>
                <i style={{backgroundImage:
                    `url(/${this.props.brand}-stacked-black.png)`,
                }}/>
                <i style={{backgroundImage:
                    `url(/${this.props.brand}-adjacent.png)`,
                }}/>
            </figure>
        );
    }
}
