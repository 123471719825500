import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/docs/src/components/Guide.js"
import Button from 'components/Button.js';
import LogoPreview from 'components/LogoPreview.js';

export default ({components, ...props}) => <MDXTag name="wrapper" Layout={DefaultLayout} layoutProps={props} components={components}>


<MDXTag name="p" components={components}>{`HealthShare and its products' logos all share a construction pattern. Each picturemark is made of a tinted speech bubble—representing communication, the common feature among all products—and a glyph symbolising the purpose of the product. This balance of consistency and uniqueness allows them to be used either as a suite or in isolation.`}</MDXTag>
<LogoPreview brand="HealthShare" className="hs"/>
<LogoPreview brand="BetterConsult" className="bc"/>
<LogoPreview brand="ConnectMe" className="cm"/>
<LogoPreview brand="SpecialistNow" className="sn"/>
<MDXTag name="p" components={components}>{`The wordmarks are `}<MDXTag name="strong" components={components} parentName="p">{`set in FF DIN Round Bold.`}</MDXTag>{` The characteristics of this font intentionally match the drawing style of the picturemark glyphs.`}</MDXTag>
<MDXTag name="p" components={components}>{`Each mark is available for use in three formats:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Isolated picturemark`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Adjacent orientation`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Stacked orientation`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`Each logo also has the option of being used with or without the HealthShare byline.`}</MDXTag>
<Button
    download
    href="/HealthShare-Logos.zip"
    label="Download HealthShare logos"
/>
<MDXTag name="h2" components={components}>{`Usage rules`}</MDXTag>
<div className="notice">
  Illustrated examples of how to and how not to use the marks will be deployed in Q1 2019.
</div>

<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Allow sufficient white space around the logo, equivalent to approximately 20% of the respective axis`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Allow sufficient contrast (should meet an AA–AAA accessibility grade)`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Do not alter colour, spacing or composition`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Do not stretch or distort`}</MDXTag>
</MDXTag></MDXTag>

export const _frontmatter = {};

  