import React from 'react';
import PropTypes from 'prop-types';


export default class Button extends React.Component {
    static propTypes = {
        download: PropTypes.bool,
        href: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    };

    static defaultProps = {
        download: false,
    };

    render() {
        return (
            <a
                download={this.props.download}
                href={this.props.href}
                role="button"
            >
                {this.props.label}
            </a>
        );
    }
}
